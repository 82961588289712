import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Icon } from '@aider/ui'
import slugify from 'slugify'
import { format, parseISO } from 'date-fns'
import { sv } from 'date-fns/locale'
import excerptHtml from 'excerpt-html'
import useGravatar from '../hooks/useGravatar'

import AnimateText from '../components/AnimateText'
import Seo from '../components/Seo'

const News = ({ pageContext: { allNews = [] } }) => {
	const url = useGravatar()

	return (
		<Fragment>
			<Seo title="Nyheter" />
			{/* Latest posts */}
			<div className="container mx-auto pt-12 md:pt-20 pb-12">
				<div className="lg:w-10/12 mx-auto px-4 sm:px-6">
					<h1 className="text-3xl sm:text-4xl lg:text-5xl font-futura font-semibold leading-tight mb-6">
						<AnimateText>Nyheter</AnimateText>
					</h1>
				</div>
				<section className="lg:w-10/12 lg:mx-auto mb-32 relative">
					<div className="z-10 w-full h-16 absolute top-0 left-0 right-0 bg-gradient-to-t from-transparent to-white" />
					<div className="z-10 w-full h-16 absolute bottom-0 left-0 right-0 bg-gradient-to-b from-transparent to-white" />
					<div className="z-10 w-16 h-full absolute bottom-0 left-0 top-0 bg-gradient-to-l from-transparent to-white" />
					<div className="z-10 w-16 h-full absolute bottom-0 right-0 top-0 bg-gradient-to-r from-transparent to-white" />
					<div className="flex flex-wrap relative">
						{allNews.map(news => (
							<Post
								key={news.id}
								className="w-full md:w-6/12 p-4 md:p-8"
							>
								<div className="z-20 relative">
									<h2 className="text-lg md:text-xl font-medium mb-3">
										<Link
											to={`/nyheter/${slugify(
												news.title,
												{
													lower: true,
												},
											)}`}
										>
											{news.title}
										</Link>
									</h2>
									<div className="flex items-center mb-3">
										<img
											className="h-12 w-12 rounded-full mr-2 overflow-hidden"
											alt={news.user?.name}
											layout="fixed"
											height={48}
											width={48}
											src={url}
										/>
										<div>
											<h4 className="text-sm font-medium leading-tight">
												{news.user?.name}
											</h4>
											<span className="text-sm text-gray-700 leading-tight">
												{format(
													parseISO(news.date),
													'd MMMM yyyy',
													{ locale: sv },
												)}
											</span>
										</div>
									</div>
									<p className="text-base text-gray-800 mb-3">
										{excerptHtml(news.body)}
									</p>

									<Link
										className="flex text-black font-medium space-x-1 items-center"
										to={`/nyheter/${slugify(news.title, {
											lower: true,
										})}`}
									>
										<span>Läs mer</span>
										<Icon
											name="chevron-right"
											className="fill-current text-black h-2.5 w-2.5"
										/>
									</Link>
								</div>
							</Post>
						))}
					</div>
				</section>
			</div>
		</Fragment>
	)
}

const Post = styled.div`
	&:nth-child(odd) {
		${tw`md:border-r border-gray-400`}
	}

	&:nth-child(1),
	&:nth-child(2) {
		${tw`md:border-b border-gray-400`}
	}

	&:nth-child(3),
	&:nth-child(4) {
		border-bottom: none;
	}
`

export default News
